<template>
  <q-dialog persistent>
    <q-card :style="{ 'font-family': settings.typography }" >
      <q-card-actions align="center">
        <q-btn icon="delete" color="red" size="25px" flat />
      </q-card-actions>
      <q-card-section>
        <div class="text-h6 text-center">{{ $t("Profile.personal-address.confirm-delete-dialog") }}</div>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn
          class="btn-home"
          :label="$t('General.confirm')"
          :style="{ backgroundColor: settings.color_accent }"
          @click="$emit('confirmDelete')"
          v-close-popup
        />
      </q-card-actions>
      <q-card-actions align="center">
        <q-btn
          class="btn-home"
          :label="$t('General.cancel')"
          :style="{ backgroundColor: settings.color_primary }"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
    },
  },
  emits: ['confirmDelete']
};
</script>

<style lang="scss" scoped>
.q-card {
  border-radius: 25px 25px 25px 0px;
}

.btn-home {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  text-transform: capitalize;
  width: 100%;
  color: white;
}
</style>
